import 'react-aspect-ratio-fence/css/style.css';
import React from 'react';
import styles from './open-courses.module.scss';
import { Link } from "gatsby";
import InfoCourseCardFluid from '@components/ui/course-info-card-fluid';
import {
  isCourseOpenForSignUp,
  getPathToCoursePage,
  getEndDateLabel,
} from '@utilities/course-data-helpers';
import { useCoursesMetadata } from '@queries/use-courses-metadata';
import PropTypes from "prop-types";

const renderHeader = (header, description) => (
  <div className={styles.description}>
    <section className={styles.heading}>
      <h2>{header}</h2>
    </section>
    <section className={styles.details}>
      {description.map(text => (
        <p className={styles.paragraph} key={String(text).substring(1, 20)}>
          {text}
        </p>
      ))}
    </section>
  </div>
);

const OpenCourses = (props) => {
  const { allCourses } = useCoursesMetadata();
  const coursesWithClosedSignUp = allCourses.filter(isCourseOpenForSignUp);

  let coursesToDisplay = [...coursesWithClosedSignUp];

  const containerStyle = {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(363px, 1fr))`,
    gridColumnGap: '29px',
    gridRowGap: '29px',
  };

  return (
    <div className={styles.container}>
      {props.limit > 0 && renderHeader(props.header, props.description)}
            
      <div style={{ ...containerStyle }}>
        {
          [...coursesToDisplay]
            .sort((a, b) => a.course.name.localeCompare(b.course.name))
            .slice(0, props.limit).map(course => {
              const { course_iter_id, applications_end_date, scholarity, course: {name: course_name}, image} = course;
              const path = getPathToCoursePage(course);

              return (
                <div key={course_iter_id}>
                  <Link to={path}>
                  <InfoCourseCardFluid
                    endDate={getEndDateLabel(applications_end_date)}
                    courseName={course_name}
                    path={getPathToCoursePage(course)}
                    scholarity={scholarity}
                    fluid={image !== null ? image.localFile.childImageSharp.fluid : null}
                    desktop={{
                      maxWidth: 540,
                      fontSize: { courseName: 40, scholarity: 15 },
                      margin: 40,
                    }}
                    mobile={{
                      maxWidth: 600,
                      fontSize: { courseName: 20, scholarity: 12 },
                      margin: 40,
                    }}
                  />
                  </Link>
                </div>
              );
            })
          }
      </div>

    </div>
  );
};

export default OpenCourses;

OpenCourses.defaultProps = {
  limit: 9000,
  header: '',
  description: [],
};

OpenCourses.propTypes = {
  limit: PropTypes.number,
  header: PropTypes.string,
  description: PropTypes.arrayOf(PropTypes.string),
};
